<template>
  <div>
    <div class="title flexB">
      <h1>KML 파일 보기</h1>
    </div>
    <div class="contents droneList">
      <div class="box one filter">
        <div class="flex">
          <label for="ex_file" style="font-weight: bold; font-size: 1.2rem;">KML</label>
          <input
              type="file"
              ref="kml"
              id="ex_file"
              @change="kmlFile()"
              accept=".kml"
          />
        </div>
        <div class="flexC">
          <label for="ex_file" style="font-weight: bold; font-size: 1.2rem;">JSON</label>
          <input
              type="file"
              ref="json"
              id="ex_file"
              @change="jsonFile()"
              accept=".json"
          />
        </div>
      </div>
      <div class="tabBox one" :style="{ 'height': (typeChangeMapShowHeight - 120) + 'px' }">
        <div class="map" style="float: left; width: 30%; border: solid 1px black" :style="{ 'height': (typeChangeMapShowHeight - 170) + 'px' } ">
          <Map ref="map" style="width : 100%; height: 100%; position: absolute; z-index:1;" :loadMapName="loadMap"/>
        </div>
        <div style="float: left; margin-left: 10px; width: 30%">
          <h1>
            kml 정보 - {{kmlInfo.name}}
          </h1>
          <div style="overflow-x: hidden;overflow-y: scroll" :style="{ 'height': (typeChangeMapShowHeight - 170) + 'px' }">
            <table>
              <tr>
                <td>
                  위치 - 위도( Latitude )
                </td>
                <td>
                  위치 - 경도( Longitude )
                </td>
                <td>
                  높이
                </td>
              </tr>
              <tr v-for="(data, i) in kmlInfo.coordinates" :key="'kmlInfo_' + i">
                <td>
                  {{data.lat}}
                </td>
                <td>
                  {{data.lng}}
                </td>
                <td>
                  {{data.height}}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="float: left;margin-left: 10px; width: 37%" :style="{ 'height': (typeChangeMapShowHeight - 170) + 'px' }">
          <h1>
            json 정보 - {{jsonInfo.name}}
          </h1>
          <div >
            <div>
              <table>
                <tr>
                  <td>
                    드론 정보
                  </td>
                  <td>
                    {{jsonInfo.droneInfo.name}}
                  </td>
                </tr>
                <tr>
                  <td>
                    safes
                  </td>
                  <td>
                    {{'datalinkLost - ' + (jsonInfo.droneInfo.failsafes == null ? '': jsonInfo.droneInfo.failsafes.datalinkLost)}}
                    <br>
                    {{'gpsLost - ' + (jsonInfo.droneInfo.failsafes == null ? '': jsonInfo.droneInfo.failsafes.gpsLost)}}
                    <br>
                    {{'lowBattery - ' + (jsonInfo.droneInfo.failsafes == null ? '': jsonInfo.droneInfo.failsafes.lowBattery)}}
                    <br>
                    {{'rcLost - ' + (jsonInfo.droneInfo.failsafes == null ? '': jsonInfo.droneInfo.failsafes.rcLost)}}
                  </td>
                </tr>
              </table>
            </div>
            <div style="overflow: scroll;" :style="{ 'height': (typeChangeMapShowHeight - 320) + 'px' }">
              <table>
                <tr>
                  <td>
                    포인트 타입
                  </td>
                  <td>
                    위치 - 위도( Latitude )
                  </td>
                  <td>
                    위치 - 경도( Longitude )
                  </td>
                  <td>
                    높이
                  </td>
                  <td>
                    좌표 체계
                  </td>
                  <td>
                    기타
                  </td>
                  <td>
                    카메라 actions
                  </td>
                </tr>
                <tr v-for="(data, i) in jsonInfo.coordinates" :key="'jsonInfo_' + i">
                  <td>
                    {{data.type}}
                  </td>
                  <td>
                    {{data.lat}}
                  </td>
                  <td>
                    {{data.lng}}
                  </td>
                  <td>
                    {{data.height}}
                  </td>
                  <td>
                    {{data.altitudeType}}
                  </td>
                  <td>
                    {{data.any}}
                  </td>
                  <td style="white-space: normal;">
                    <label style="text-overflow: fade;"  v-for="(item, i ) in data.actions"  :key="'jsonInfo_actions_' + i" >
                      {{jsonInfoActions(item)}}<br>
                    </label>

                  </td>
                </tr>
              </table>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import MapDataType from "@/components/map/enum/MapDataType";
import MapLineData from "@/components/map/data/MapLineData";
import { baseUrl } from "@/config/env";
import Map from "@/components/map/Map";
export default {
  components: { Map },
  name: "FileParse",
  data() {
    return {
      baseUrl: baseUrl,
      accountId: localStorage.getItem("accountId"),
      ko: ko,
      moment: moment,
      loadMap : 'kakao',
      typeChangeMapShowHeight: 0,
      kmlInfo : {
        name : null,
        coordinates : null,
      },
      jsonInfo : {
        name : null,
        coordinates : null,
        droneInfo : {
          failsafes : null,
          name : null,
        }
      }
    };
  },
  created() {
    this.mapWidth = window.innerWidth - 322;
    this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.$refs.map.onClickShowLine(true)
  },
  methods: {
    xmlFindChildNodeToGetNodeForName(child,findName) {
      var result = [];
      for(let index = 0; index < child.length; index ++) {
        let item = child[index];
        if( item.nodeName == findName) {
          result.push(item);
        }
      }
      return result;
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    handleResize(){
      this.mapWidth = window.innerWidth - 322;
      this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    },
    kmlFile(){
      this.file = this.$refs.kml.files[0];
      let reader = new FileReader();
      this.kmlInfo.name = null;
      this.kmlInfo.coordinates = null;
      this.$refs.map.onDeleteLineAt(MapDataType.ID_LIVE, "kml")
      reader.onload = (e) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(e.target.result, "application/xml");
        var rootChildren = doc.documentElement

        let rootItem = this.xmlFindChildNodeToGetNodeForName(rootChildren.children, "Document");

        var kmlInfoName = [];
        if(rootItem.length > 0) {
          kmlInfoName = this.xmlFindChildNodeToGetNodeForName(rootItem[0].children, "name");
        }
        if(kmlInfoName.length > 0 ) {
          this.kmlInfo.name = kmlInfoName[0].textContent;
        }

        let placemarkNode = this.xmlFindChildNodeToGetNodeForName(rootItem[0].children, "Placemark");

        let kmlInfoCoordinates = [];

        placemarkNode.forEach(item => {
          let lineStringNode = this.xmlFindChildNodeToGetNodeForName(item.children, "LineString");
          let pointNode = this.xmlFindChildNodeToGetNodeForName(item.children, "Point");
          var coordinatesNode = [];

          if(lineStringNode.length > 0) {
            coordinatesNode = this.xmlFindChildNodeToGetNodeForName(lineStringNode[0].children, "coordinates");
            if(coordinatesNode.length > 0) {
              let coordinates = coordinatesNode[0].textContent.split(" ");
              for(let index = 0 ; index < coordinates.length; index++) {
                let coordinate = coordinates[index].split(",");
                let info = {
                  lng : coordinate[0],
                  lat : coordinate[1],
                  height : coordinate[2],
                }
                if(index == 0) {
                  this.$refs.map.onLngLatToMoveCenter(coordinate[0], coordinate[1])
                }
                kmlInfoCoordinates.push(info)
              }
            }
          }

          if(pointNode.length > 0) {
            let pointNodeName  = this.xmlFindChildNodeToGetNodeForName(item.children, "name");
            coordinatesNode = this.xmlFindChildNodeToGetNodeForName(pointNode[0].children, "coordinates");
            let coordinate = coordinatesNode[0].textContent.split(",");
            let info = {
              lng : coordinate[0],
              lat : coordinate[1],
              height : coordinate[2],
            }
            if(pointNodeName.length > 0){
              if(pointNodeName[0].textContent == "1"){
                this.$refs.map.onLngLatToMoveCenter(coordinate[0], coordinate[1])
              }
            }
            kmlInfoCoordinates.push(info)
          }
        })

        this.createMapLine("kml",kmlInfoCoordinates);
        this.kmlInfo.coordinates = kmlInfoCoordinates;
      };
      reader.readAsText(this.file);
    },
    jsonInfoActions(item){
      let fruitKeys = Object.keys(item);
      var result = "";

      fruitKeys.forEach(data => {
        var string = `${data} - ${item[data]}, `;
        result += string
      })

      return result;
    },
    jsonFile(){
      this.file = this.$refs.json.files[0];
      let reader = new FileReader();
      this.jsonInfo.name = null;
      this.jsonInfo.coordinates = null;
      this.jsonInfo.droneInfo.failsafes = null;
      this.jsonInfo.droneInfo.name = null;
      this.$refs.map.onDeleteLineAt(MapDataType.ID_LIVE, "json");
      reader.onload = (e) => {
        let jsonData = JSON.parse(e.target.result);
        this.jsonInfo.name = jsonData.route.name;
        this.jsonInfo.droneInfo.name = jsonData.vehicleProfiles[0].platformCode;
        this.jsonInfo.droneInfo.failsafes = jsonData.route.failsafes;
        let jsonInfoCoordinates = [];

        for(let index = 0 ; index < jsonData.route.segments.length; index++) {
          let coordinate = jsonData.route.segments[index]
          let type = coordinate.type;
          let lat = null;
          let lng = null;
          let height = null;
          let altitudeType = null;
          let any = null;
          var actions = coordinate.actions;

          if(type == "Photogrammetry") {
            let polygon = coordinate.polygon;
            polygon.points.forEach(item => {
              let info = {
                type :type,
                lat : item.latitude,
                lng : item.longitude,
                height : item.altitude,
                altitudeType : item.altitudeType,
                any : null,
              }
              jsonInfoCoordinates.push(info)
            })
          }else if(type == "Circle") {
            lat = coordinate.circle.center.latitude;
            lng = coordinate.circle.center.longitude;
            height = coordinate.circle.center.altitude;
            altitudeType = coordinate.circle.center.altitudeType;
            any = "redius -" + coordinate.circle.radius;
          } else if(type == "Waypoint"){
            lat = coordinate.point.latitude;
            lng = coordinate.point.longitude;
            height = coordinate.point.altitude;
            altitudeType = coordinate.point.altitudeType;
          }
          if(lat != null && lng != null) {
            let info = {
              type : type,
              lat : lat,
              lng : lng,
              height : height,
              altitudeType : altitudeType,
              any : any,
              actions : actions,
            }
            jsonInfoCoordinates.push(info);

          }
        }
        if(jsonInfoCoordinates.length > 0) {
          this.$refs.map.onLngLatToMoveCenter(jsonInfoCoordinates[0].lng, jsonInfoCoordinates[0].lat)
        }
        this.jsonInfo.coordinates = jsonInfoCoordinates;
        this.createMapLine("json",jsonInfoCoordinates);
      };
      reader.readAsText(this.file);
    },
  },
};
</script>
